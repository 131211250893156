<template>
  <div class="meta-list-table">
    <el-header v-if="showTableHeader">
      <div class="table-header" >
        <slot name="header"></slot>
      </div>
    </el-header>
    <el-table
        :data="tableData"
        tooltip-effect="dark"
        :height="tableHeight"
        style="width: 100%"
        @cell-click="handleClick"
        :row-style="{cursor:'pointer'}"
        :empty-text="emptyText"
        :table-layout="tableLayout"
        :border="border"
        :header-row-class-name="headerRowClassName"
        :row-class-name="rowClassName"
        ref="table"
        :show-header="show_header"
        :default-sort="defaultSort"
        @sort-change="sortChange"
    >
      <el-table-column
          align="center"
          width="60"
          v-if="showCheckbox"
      >
        <template #default="scope">
          <el-checkbox v-model="scope.row.checked" @change="handleCheckBoxChange(scope,$event)"></el-checkbox>
        </template>
      </el-table-column>
      <slot name="default"></slot>
    </el-table>
  </div>
</template>

<script>
import {colorLog} from "../../util";

export default {
  name: "MetaListTable",
  props:{
    tableData: Array,
    showTableHeader:{
      default:true,
      required:false,
    },
    show_header:{
      type:Boolean,
      default:true,
      required:false,
    },
    tableHeight:{
      type:[String,Number],
      default:'100%'
    },
    emptyText:{
      type:[String],
      default:'暂无数据'
    },
    tableLayout:{
      type:[String],
      default:'auto'
    },
    showCheckbox:Boolean,
    border: Boolean,
    headerRowClassName: {
      type:[String,Function],
    },
    rowClassName: {
      type:[String,Function],
    },
    defaultSort: {
      type: Object,
    },

  },
  methods:{
    handleClick(row,column,cell,event){
      // colorLog.blue('handleClick',row,column,cell,event)
      if(column.no == 0 && this.showCheckbox){ //这里做了一个防止误点的处理
        return;
      }

      sessionStorage.setItem(this.$route.name,JSON.stringify(this.$route.query));
      this.$emit('cell-click',...arguments);
    },
    handleCheckBoxChange(scope,value){
      // let target = this.tableData.find((item,index) => {
      //   return index == scope.$index;
      // });
    },
    sortChange(){
      this.$emit('sort-changed',...arguments)
    }
  },
  created() {
    //提取sessionStorage存储的路由信息
    const routerStorage =sessionStorage.getItem(this.$route.name);
    let parsedRouterStorage;
    try{
      parsedRouterStorage = JSON.parse(routerStorage);
    }catch (e){
      colorLog.orange('MetaListTable: JSON.parse出错或者MetaList没有得到之前的query数据');
    }
    if(parsedRouterStorage){
      // for(let key in parsedRouterStorage){
      //   if(['false','true'].includes(parsedRouterStorage[key])){
      //     parsedRouterStorage[key] = parsedRouterStorage[key] == 'true' ? true : false;
      //   }
      // }
      colorLog.blue(`${this.$route.name} routerStorage`,parsedRouterStorage);
      this.$emit('routerQueryStorage',parsedRouterStorage)    ;
    }

    sessionStorage.setItem(this.$route.name,'');
  },
  mounted() {
    //自定义wrap元素的class 加上 'custom-scroller'
    this.$refs.table.$el.querySelector('.el-table__body-wrapper').classList.add('custom-scroller');
  },
}
</script>

<style scoped>
.meta-list-table{
  width: 100%;
  height:100%;
  --headerHeight:50px;
}

.el-header{
  text-align: left;
  border-bottom:1px solid #e0e0e0;
  background-color: white;
  padding: 7px 14px;
  height: var(--headerHeight);
}
.el-table{
  max-height: calc(100% - var(--headerHeight));
}

.meta-list-table >>> .el-table__header-wrapper th{
  font-weight:300;
}

.meta-list-table >>> .el-table__row:hover .icon-more {
  background-position: center;
  display: inline-block;
  cursor: pointer;
}

.meta-list-table >>> .el-table__row .icon-more {
  display: none;

  height: 46px;
  width: 46px;
  margin: auto;
  background-position: center;
}

.meta-list-table >>> .el-table__body-wrapper {

  height: calc(100% - 100px);
}

.meta-list-table >>> .el-table td {
  padding: 0;
}

.meta-list-table >>> .el-table th {
  height: 46px;
  padding: 0px;

}

.meta-list-table .el-table >>> .cell {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 46px;
  line-height: 46px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header >>> .action {
  flex: 1;
  display: flex;
  align-items: center;
  height: 32px;
}

.table-header >>> .action-btn {
  padding: 3px 7px;
  border-radius: 4px;
  line-height: 20px;
  cursor: pointer;
  position: relative;
}

.table-header >>> .action-btn:hover {
  background: #EEE;
}

.table-header >>> .silver{
  color: #999;
}

.table-header >>> .contract-filter-btn {
  cursor: pointer;
  color: #5280FF;
  display: flex;
  align-items: center;
}

.table-header >>> .contract-filter-btn svg {
  margin-right: 5px;
}

.table-header >>> .menu-item {

  padding: 10px;
  text-align: left;
  cursor: pointer;
}

.table-header >>> .menu-item:hover {
  background: #e2e2e2;
}

</style>
